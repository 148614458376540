<script>
import TeacherWizardLayout from "../../layouts/teacher-wizard.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


export default {
  page: {
    title: "ระบบหลัก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    TeacherWizardLayout,
    PageHeader,
  },
  data() {
    return {
      title: "ระบบหลัก",
      items: [
        {
          text: "ShowShare",
        },
        {
          text: "ลงทะเบียนครูแพทย์แผนไทย",
          active: true,
        },
      ],
      currentUser: {},
      loaded: false,
      loaderHeight: window.innerHeight * 0.1,
    };
  },
  computed: {},
  mounted() {
    this.getLatestUserInfo();
  },
  methods: {
    async getLatestUserInfo() {
      console.log("================");
      console.log("Load New Data");
      const user = JSON.parse(localStorage.getItem('auth.user')) || {};
      console.log("user: user")
      if (user == {}) {
        return false;
      }
      const resp = await this.$store.dispatch("apiRestful/getLatestUserInfo", {
        uid: user.uid,
      }).catch((error) => {
        console.log("error:", error);
        return error;
      });
      if (resp) {
        localStorage.setItem('auth.user', JSON.stringify(resp));
      }
      console.log("================");
      this.loaded = true;
      return true;
    }
  },
};
</script>

<template>
  <div>
    <div id="preloader" v-if="!loaded">
      <div id="status">
        <img src="@/assets/images/loader.svg" :height="loaderHeight" />
      </div>
    </div>
    <TeacherWizardLayout v-else-if="loaded" v-on:reloadUserData="getLatestUserInfo">
      <PageHeader :title="title" :items="items" />
    </TeacherWizardLayout>
  </div>
</template>